import { template as template_2d05297e963e461a9b38f7ffbf763484 } from "@ember/template-compiler";
const FKText = template_2d05297e963e461a9b38f7ffbf763484(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
