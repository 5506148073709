import { template as template_9cd7f57d76cb450eb2e0c6a0c653f989 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class MessagesSecondaryNav extends Component {
    get messagesNav() {
        return document.getElementById("user-navigation-secondary__horizontal-nav");
    }
    static{
        template_9cd7f57d76cb450eb2e0c6a0c653f989(`
    {{#in-element this.messagesNav}}
      {{yield}}
    {{/in-element}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
