import { template as template_77e33dd0edca498f8e40c246f34a135f } from "@ember/template-compiler";
import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import { i18n } from "discourse-i18n";
const GroupActivityFilter = template_77e33dd0edca498f8e40c246f34a135f(`
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupActivityFilter;
