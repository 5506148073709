import { template as template_4a38c5d8d8d44e80ba1a12f4de9c9e20 } from "@ember/template-compiler";
const FKLabel = template_4a38c5d8d8d44e80ba1a12f4de9c9e20(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
