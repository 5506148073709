import { template as template_af04e8706b5346d2a3b9162c7f14a04b } from "@ember/template-compiler";
import bodyClass from "discourse/helpers/body-class";
const AddTopicStatusClasses = template_af04e8706b5346d2a3b9162c7f14a04b(`
  {{#if @topic.invisible}}
    {{bodyClass "topic-status-unlisted"}}
  {{/if}}
  {{#if @topic.pinned}}
    {{bodyClass "topic-status-pinned"}}
  {{/if}}
  {{#if @topic.unpinned}}
    {{bodyClass "topic-status-unpinned"}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddTopicStatusClasses;
